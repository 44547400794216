<script lang="ts" setup>
import { GlobalModalKey } from '~/resources/modal/modal.interface';

// #region Globals
const route = useRoute();
const { resetState } = useResetState();
const dayjs = useDayjs();
// #endregion

// #region Props
const props = defineProps({
   planningRequest: { type: Object as PropType<PlanningRequest>, required: true },
});
// #endregion

// #region Confirmation Approved Modal
const confirmationModalRoute = computed(() => ({
   ...route,
   hash: `${GlobalModalKey.PlanningRequest}?id=${props.planningRequest.sync_id}`,
}));
// #endregion
const planningRequest = computed(() => props.planningRequest);
const { color } = useColorCode(planningRequest);

// #region Planning Request Status
const isPassedDeadline = computed(() => {
   if (!planningRequest.value) return false;
   return dayjs().isAfter(dayjs(planningRequest.value.must_be_handled_at), 'day');
});
// #endregion

// #region Planning Request Status
const state = ref();
const showCantWorkModal = ref(false);
const planningRequestStore = usePlanningRequestStore();

async function refusePlanningRequest() {
   try {
      await planningRequestStore.changePlanningRequestState(props.planningRequest.sync_id, PlanningRequestStateKey.REFUSED);
      resetState();
      showCantWorkModal.value = false;
   } catch {}
}

function refuse() {
   showCantWorkModal.value = true;
}

async function confirm() {
   try {
      await planningRequestStore.changePlanningRequestState(props.planningRequest.sync_id, PlanningRequestStateKey.APPROVED);
      resetState();
   } catch {}
}

function changePlanningStatus(value?: boolean) {
   if (value === true) {
      confirm();
   } else if (value === false) {
      refuse();
   }
}

function closeCantWorkModal() {
   showCantWorkModal.value = false;
   state.value = undefined;
}

watch(state, changePlanningStatus);
// #endregion
</script>

<template>
   <NuxtLink prefetch :to="confirmationModalRoute" :class="'sent-card'">
      <small class="sent-card__greeting">
         {{ planningRequest.period_summary }}
      </small>
      <div class="sent-card__body">
         <PlanningRequestHeader class="sent-card__header" :planning-request="planningRequest" />

         <p :class="'sent-card__period'">
            {{ planningRequest.period }}
         </p>
      </div>
      <div v-if="!isPassedDeadline" class="sent-card__actions">
         <FormButtonToggle v-model="state" :mode="'planning'" />
      </div>
      <Teleport to=".app">
         <ScheduleCantWorkModal
            v-if="showCantWorkModal"
            :ask-for-reason="false"
            :planning-request="planningRequest"
            @on-close="closeCantWorkModal"
            @on-confirm="refusePlanningRequest"
         />
      </Teleport>
   </NuxtLink>
</template>

<style lang="scss" scoped>
.sent-card {
   border-left: var(--nxt-card-accent-width) solid v-bind(color);
   border-radius: var(--nxt-radius);
   padding: var(--nxt-gutter-small);

   text-decoration: none;
   min-height: toRem(80);
   color: var(--nxt-dark);
   background-color: var(--nxt-card-background);
   border-radius: var(--nxt-radius);

   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto 1fr;
   gap: var(--nxt-gutter-small);

   &:visited,
   &:active,
   &:hover {
      text-decoration: none;
      color: var(--nxt-dark);
   }
}

.sent-card__greeting {
   grid-column: span 2;
}
.sent-card__body {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}
.sent-card__actions {
   padding-right: var(--nxt-gutter);
}

.sent-card__period {
   margin: 0;
}
</style>
